import '../styles/login.scss';
import '../styles/button.scss';

import { Button } from '@mui/base/Button';
import { Modal } from '@mui/base/Modal';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { Box,styled } from '@mui/system';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';

import  correct from '../assets/images/correct.png';


export default function NestedModal() {
  const [open, setOpen] = React.useState( false );
  const handleOpen = () => {
    setOpen( true );
  };
  const handleClose = () => {
    setOpen( false );
  };

  return (
    <div>

      <Grid className="yellow-bg">
        <Box
          className="auth-box"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >

          <img src={correct} alt="correct"  className="correct" width={100}/>

          <h1 className="login">Thank you for sharingcustomer details. We appreciate your trust in Bubble Insurance, we will promptly provide your customer with personalized home insurance quotes from top-rated carriers.</h1>

          <TriggerButton onClick={handleOpen} className="signup-submit">What's Next?</TriggerButton>

        </Box>

      </Grid>

      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
          <h2 id="parent-modal-title">Streamline Insurance for Your Clients: Explore Bubble's Agent Portal!</h2>

          <p id="parent-modal-description">
            Check out Bubble's agent portal, designed to seamlessly suggest best insurance options for your buyers. Share home insurance quotes in just a click, keep a track of your customer's purchase progress and much more!
          </p>

          <Button className="button-auth orangebtn" variant="round" >
            Login to agent portal
          </Button>

          <Button className="button-auth active" variant="round" onClick={handleClose} >
            Remind me later
          </Button>


        </Box>
      </StyledModal>
    </div>
  );
}


const Backdrop = React.forwardRef( ( props, ref ) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx( { 'MuiBackdrop-open': open }, className )}
      ref={ref}
      {...other}
    />
  );
} );

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool
};

const blue = {
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF'
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f'
};

const StyledModal = styled( Modal )`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled( Backdrop )`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = ( theme ) => ( {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-52%, -50%)',
  width: 415,
  borderRadius: '12px',
  padding: '50px 30px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
  background: '#fff',
  textAlign: 'center'
} );

const TriggerButton = styled( Button )(
  ( { theme } ) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  border-radius: 12px;
  padding: 6px 12px;
  line-height: 1.5;
  background: transparent;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[100] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:focus-visible {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }
  `
);
